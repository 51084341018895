import React, { Component } from 'react';
import {
  BookmarkIcon, BallIcon, GroupIcon, TriangleIcon, GradeSchoolIcon, QALiveIcon,
} from '../../components/Icons/Icons'
import { Link } from 'react-router-dom'
import './CategoriesPage.scss'
import CommonMethods from '../../utils/CommonMethods';
import KEY_MAPPING_CONST from '../../constants/KEY_MAPPING_CONST';
import Modal from '../../components/Modal/Modal';
import APP_CONST from '../../constants/APP_CONST';
const {
  VK_BACK_SPACE,
  VK_ENTER,
  VK_LEFT,
  VK_RIGHT,
  VK_UP,
  VK_DOWN,
} = KEY_MAPPING_CONST

const categories = [
  {
    label: 'Big School',
    image: <GradeSchoolIcon />,
    className: 'big-school',
  },
  {
    label: 'Learn',
    image: <BookmarkIcon />,
    className: 'learner',
  }, {
    label: 'Play',
    image: <BallIcon />,
    className: 'play',
  }, {
    label: 'Teach',
    image: <GroupIcon />,
    className: 'teach',
  }, {
    label: 'Holiday Festival',
    image: <TriangleIcon />,
    className: 'life',
  }, {
    label: 'Q and A live 2017',
    image: <QALiveIcon />,
    className: 'qa-live',
  }
  // }, {
  //   label: 'Health',
  //   image: <HeartIcon />,
  //   className: 'health',
  // }, {
  //   label: 'Exam Questions 2017',
  //   image: <DocumentIcon />,
  //   className: 'exam',
  // }, {
  //   label: 'Second Chance Matric',
  //   image: <HatIcon />,
  //   className: 'matric',
  // }
]

export default class Categories extends Component {
  categoryId = CommonMethods.getParameterByName('categoryId')

  getDefaultFocusElementIndex() {
    const index = categories.findIndex(category => CommonMethods.labelToId(category.label) === this.categoryId)
    return index === -1 ? 0 : index
  }

  state = {
    showPopup: false,
    focusElementIndex: this.getDefaultFocusElementIndex(),
  }
  focusElemt() {
    const elemt = document.querySelector(`.category-${this.state.focusElementIndex}`);
    elemt.focus()
  }

  componentDidMount() {
    this.focusElemt()
    this.scrollToTop()
    document.addEventListener("keydown", this.handleRemoteKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleRemoteKey, false)
  }

  componentDidUpdate() {
    this.focusElemt()
    this.scrollToTop()
  }

  scrollToTop() {
    if (this.state.focusElementIndex < APP_CONST.NO_OF_ELEMENT_IN_ROW) {
      window.scrollTo(0, 0);
    }
  }

  openPopup() {
    this.setState({ showPopup: true })
  }

  closePopup() {
    this.setState({
      showPopup: false
    })
  }


  handleRemoteKey = (ev) => {

    switch (ev.keyCode) {
      case VK_BACK_SPACE:
        if (this.state.showPopup) {
          this.closePopup()
        } else {
          this.openPopup()
        }
        break;
      case VK_ENTER:
        if (this.state.showPopup) {
          this.closePopup()
          window.close()
        } else {
          const elemt = document.querySelector(`.category-${this.state.focusElementIndex}`);
          elemt.click()
        }
        break
      case VK_LEFT: {
        const focusElementIndex = this.state.focusElementIndex - APP_CONST.NEXT_ELEMENT_MOVE
        if (focusElementIndex >= 0) {
          this.setState({ focusElementIndex })
        } else {
          this.setState({ focusElementIndex: categories.length - 1 })
        }
        break
      }
      case VK_RIGHT: {
        const focusElementIndex = this.state.focusElementIndex + APP_CONST.NEXT_ELEMENT_MOVE
        if (focusElementIndex <= categories.length - 1) {
          this.setState({ focusElementIndex })
        } else {
          this.setState({ focusElementIndex: 0 })
        }
        break
      }
      case VK_UP: {
        this.setState({
          focusElementIndex: CommonMethods.getUpIndex(this.state.focusElementIndex)
        })
        break
      }
      case VK_DOWN: {
        this.setState({
          focusElementIndex: CommonMethods.getDownIndex(this.state.focusElementIndex, categories.length)
        })
        break
      }
      default:
    }
    // Block the browser from handling the keydown event.
    ev.preventDefault();
  }

  render() {
    const { showPopup } = this.state
    return (
      <main className="category-page">
        <ul className="category-list">
          {
            categories.map((category, index) => <li key={category.label}>
              <Link
                to={`/videos?categoryId=${CommonMethods.labelToId(category.label)}`}
                className={`category zoom-effect category-${category.className} category-${index}`}
              >
                {category.image}
                <p className="label">{category.label}</p>
              </Link>
            </li>)
          }
        </ul>

        {showPopup && <Modal className="confirmation-popup">
          <h3 className="heading">Please click OK to close the app</h3>
        </Modal>}
      </main>
    )
  }

}